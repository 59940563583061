@import '../../variables.scss';

.item {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0 1rem;
  .textinput {
    width: 6rem;
  }
  span {
    font-size: 1.2rem;
    font-weight: 600;
  }
  button {
    border: 0;
    background-color: transparent;
    color: $textColor;
    transition: background 0.2s ease;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    &:hover {
      background-color: rgba(#fff, 0.05);
    }
  }
}
