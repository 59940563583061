@import '../../../variables.scss';
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  /* background: ${theme.colors.primary}; */
  color: $textColor;
  border-radius: 6px;
  padding: 10px 18px;
  -webkit-font-smoothing: antialiased;
  font-family: 'Inter', sans-serif;
  font-size: 1.1em;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  background-color: $primary;
  &.focused {
    &::after {
      border-radius: 25%;
      background-color: rgba(255, 255, 255, 0.3);
      box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.3);
      content: '';
      position: absolute;
      transform: scale(0);
      width: 50%;
      height: 10px;
      animation-name: ripple;
      animation-duration: 0.8s;
    }
  }
  &:disabled {
    background-color: $gray;
    color: $lightGray;
  }
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
