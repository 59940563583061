@import '../../variables.scss';

nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 60px;
  overflow: hidden;
  background: $backgroundMenu;
  transition: width 0.05s linear;
  -webkit-transition: width 0.05s linear;
  z-index: 10;
  padding: 0;
  padding-top: 10px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  &:hover {
    width: 180px;
    overflow: visible;
  }
  ul {
    padding: 0;
    & > li {
      position: relative;
      display: block;
      width: 180px;
      padding: 0;
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1.2rem;
        color: #999;
        font-size: 1rem;
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        padding: 10px 0;
        padding-left: 18px;
        font-weight: 500;
      }
    }
  }
}

nav > ul,
nav > ul > li {
  outline: 0;
}

nav > ul > li > a {
  text-decoration: none;
}

.nav-text {
  width: 120px;
  height: 20px;
}

.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

.main-menu li:hover > a,
.main-menu li.active > a,
.main-menu li > a:focus {
  text-decoration: none;
  color: #fff;
  background-color: $primary;
}
