@import '../../../variables.scss';
.wrapper {
  .input {
    display: flex;
    background: transparent;
    border-radius: 0.4rem;
    transition: all 0.3s ease;
    position: relative;
    label {
      display: block;
    }
    input {
      -webkit-font-smoothing: antialiased;
      font-family: 'Inter', sans-serif;
      font-size: 1.1em;
      font-weight: 500;
      padding: 0.8rem 1rem;
      border-radius: 0.4rem;
      outline: none;
      background: transparent;
      color: $textColor;
      width: 100%;
      border: 1px solid $gray;
      transition: border 0.3s ease;
      &:hover,
      &:focus {
        border: 1px solid $primary;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 0 transparent inset;
        filter: none;
      }
    }
  }
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}
