@import './variables.scss';

.message {
  padding: 10px 12px;
  border-radius: 4px;

  &-error {
    background-color: $red;
    color: $darkGray;
    font-weight: 500;
  }
  &-warning {
    background-color: $yellow;
    color: $darkGray;
    font-weight: 500;
  }
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $darkGray;
  color: $lightGray;
  z-index: 10;
  .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: 600;
  }
}

.chips {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 1rem;
  padding: 10px 0;
  &-item {
    padding: 10px 15px;
    border: 1px solid $gray;
    border-radius: 8px;
  }
}

// THE LOADING EFFECT
.skeleton {
  background-color: rgba(#fff, 0.05);
  border-radius: 6px;
  // The shine that's going to move across the skeleton:
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.06),
    rgba(#fff, 0)
  );
  background-size: 80px 100%; // width of the shine
  background-repeat: no-repeat; // No need to repeat the shine effect
  background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
  animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
}

@keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

.toast {
  position: fixed;
  right: 0;
  top: 0;
  padding: 30px;
  overflow: hidden;
  z-index: 20;
  font-size: 1.2em;

  &-content {
    background-color: rgb(59, 141, 249);
    padding: 20px 50px 20px 30px;
    border-radius: 8px;
    position: relative;

    button {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: transparent;
      border: 0;
    }
    &.error {
      background-color: $red;
    }
    transition: all 0.75s ease;
    transform: translateX(120%);
    &.active {
      transform: translateX(0);
    }
  }
}
