@import './variables.scss';
@import './components.scss';
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: 'Inter', sans-serif;
  overflow: hidden;
}

main {
  background-color: $backgroundColor;
  color: $textColor;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  span {
    font-size: 0.8em;
    font-weight: 500;
    padding: 0 10px;
  }
}

a {
  color: $textColor;
  text-decoration: none;
}

.link {
  font-weight: 600;
  &:hover {
    color: $primary;
  }
}

#root,
#__next {
  isolation: isolate;
}

input,
select {
  color: $textColor !important;
  -webkit-text-fill-color: $textColor !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.container {
  height: 100vh;
  padding-left: 4rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 34rem;
  &_booking {
    display: block;
    padding: 10px 10px 1px 4rem;
  }
}

.content {
  padding: 15px 15px 35px 35px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 4rem;
    transition: background 0.2s ease;
    .icon {
      border-radius: 50%;
      padding: 8px;
      &:hover {
        background-color: rgba(#000, 0.2);
      }
    }
  }
  .scroll {
    overflow-y: auto;
    height: calc(100vh - 110px);
    padding-right: 15px;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #222;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: #222;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;

      background-color: #333;
    }
  }

  footer {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;

    justify-content: space-between;
  }
}

.wrap {
  background-color: $backgroundMenu;
  padding: 30px 40px 40px;
  margin: 20px 0;
  border-radius: 10px;
}

.list {
  list-style: none;
  padding: 0;
  margin-top: 20px;

  li {
    background-color: $darkGray;
    border-radius: 10px;
    color: white;
    border: 1px solid $darkGray;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    .list-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    a,
    .list-item {
      padding: 20px 30px;
      display: block;
      flex: 1;
    }
    &:hover {
      border: 1px solid $primary;
    }
    .action {
      display: flex;
    }
  }
}

.grid {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  width: 100%;

  .s3 {
    width: 25%;
  }
  .s6 {
    width: 50%;
  }
  .s9 {
    width: 75%;
  }
  .s12 {
    width: 100%;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  overflow-y: auto;
  height: calc(100vh - 220px) !important;

  article {
    background-color: $darkGray;
    border-radius: 10px;
    color: white;
    border: 1px solid $gray;
    padding: 20px;
    margin-top: 10px;
    position: relative;
    display: grid;
    grid-template-columns: 17rem 1fr;

    .border {
      border: 1px solid $gray;
      border-radius: 30rem;
      padding: 5px 12px;
      display: inline-block;
      font-size: 1.1rem;
      font-weight: 600;
      margin: 8px 0;
    }
    .sector {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-right: 1px solid $backgroundColor;
      // gap: 0.3rem;
      padding: 0 0 0 20px;
      h4,
      h3 {
        padding: 0 0 5px;
      }
    }
    .ticket {
      .thin {
        font-size: 11px;
      }
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      content: '';
      width: 50px;
      height: 50px;
      border-radius: 50px;
      transform: translateX(-50%) translateY(-50%);
      background-color: $backgroundColor;
    }
  }
}

.table {
  thead {
    th {
      text-align: left;
      padding: 10px;
      opacity: 0.4;
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
  tbody {
    td {
      text-align: left;
      padding: 15px 10px;
      border-top: 1px solid $gray;
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
  // display: flex;
  // gap: 1rem;
  // article {
  //   background-color: $darkGray;
  //   border-radius: 10px;
  //   color: white;
  //   border: 1px solid $darkGray;
  //   padding: 20px 30px;
  // }
}

.btnprint {
  width: 150px;
}
