@import '../../variables.scss';

.login {
  height: 100vh;
  background-color: $darkGray;
  display: grid;
  place-items: center;
  &-container {
    background-color: $backgroundColor;
    padding: 20px 25px;
    border-radius: 10px;
    color: $textColor;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }
}
