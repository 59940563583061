@import '../../variables.scss';

.item {
  display: flex;
}

.cart {
  border-left: 2px solid $backgroundMenu;
  height: 100%;
  &-message {
    position: absolute;
    width: 540px;

    padding: 20px;
  }
  &-container {
    padding: 30px;
    height: 100%;

    display: flex;
    flex-direction: column;
    article {
      background-color: $darkGray;
      padding: 10px 15px;
      border-radius: 6px;
      //border-top: 1px solid ;
      margin: 5px 0 0;
      .header {
        display: flex !important;
        justify-content: space-between;
        align-items: flex-start;
      }
      .footer {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
      }
      .icon {
        padding: 6px;
        cursor: pointer;
        border-radius: 50%;

        &:hover {
          background-color: rgba(#ffffff, 0.1);
          svg {
            stroke: $textColor;
          }
        }
      }
    }
  }
  &-header {
    padding-bottom: 10px;
  }
  &-content {
    padding: 0px 5px 0px 0;
    flex: 1;
    overflow-y: auto;
    max-height: 78vh;
    position: relative;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #222;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: #222;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;

      background-color: #333;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    align-items: center;
  }

  .empty {
    position: absolute;
    left: 38%;
    top: 38%;
    opacity: 0.2;
    text-align: center;
  }
}
