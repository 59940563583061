@page {
  margin: 0;
}

.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

/** Paper sizes **/
#print.zebra .sheet {
  width: 65mm;
  height: 110mm;
}
#print.zebra.landscape .sheet {
  width: 110mm;
  height: 65mm;
}
#print.A3 .sheet {
  width: 297mm;
  height: 419mm;
}
#print.A3.landscape .sheet {
  width: 420mm;
  height: 296mm;
}
#print.A4 .sheet {
  width: 210mm;
  height: 296mm;
}
#print.A4.landscape .sheet {
  width: 297mm;
  height: 209mm;
}
#print.A5 .sheet {
  width: 148mm;
  height: 209mm;
}
#print.A5.landscape .sheet {
  width: 210mm;
  height: 147mm;
}
#print.letter .sheet {
  width: 216mm;
  height: 279mm;
}
#print.letter.landscape .sheet {
  width: 280mm;
  height: 215mm;
}
#print.legal .sheet {
  width: 216mm;
  height: 356mm;
}
#print.legal.landscape .sheet {
  width: 357mm;
  height: 215mm;
}

/** Padding area **/
.sheet.padding-10mm {
  padding: 10mm;
}
.sheet.padding-15mm {
  padding: 15mm;
}
.sheet.padding-20mm {
  padding: 20mm;
}
.sheet.padding-25mm {
  padding: 25mm;
}

/** For screen preview **/
@media screen {
  body {
    background: #e0e0e0;
  }
  .sheet {
    background: white;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
    margin: 5mm auto;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  #print.A3.landscape {
    width: 420mm;
  }
  #print.A3,
  #print.A4.landscape {
    width: 297mm;
  }
  #print.A4,
  #print.A5.landscape {
    width: 210mm;
  }
  #print.A5 {
    width: 148mm;
  }
  #print.letter,
  #print.legal {
    width: 216mm;
  }
  #print.letter.landscape {
    width: 280mm;
  }
  #print.legal.landscape {
    width: 357mm;
  }
}

.sheet {
  font-size: 9px;
  position: relative;
  .ticket {
    position: absolute;
    left: 12px;
    top: 14px;
  }
  .footer {
    padding: 5px 10px 0;
    text-align: center;
  }
  .sector {
    padding: 25px 10px 0;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    .details {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      font-size: 11px;
      font-weight: 500;
    }
  }
  article {
    padding: 3px 10px 10px 10px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    .upper {
      text-transform: uppercase;
    }
    .code {
      padding: 0 0 0 10px;
      width: 80px;
      line-height: 1em;
      .id {
        padding: 3px;
        border: 1px solid #222;
        border-radius: 4px;
        margin: 8px;
        text-align: center;
        font-size: 1.1em;
        font-weight: 600;
      }
      .small {
        word-break: break-word;
        padding-top: 10px;
        font-size: 0.85em;
      }
    }
    .content {
      padding: 0 15px 0;
      display: flex;
      .user {
        padding-bottom: 3px;
        font-size: 1.4em;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.4em;
        padding: 4px 0;
        p {
          font-size: 0.9em;
        }
      }
      .item {
        padding: 0;
        font-size: 13px;
        font-weight: 600;
        display: flex;
        flex-flow: row wrap;

        .right {
          margin-left: auto;
        }
        &.medium {
          font-size: 11px;
        }
        &.small {
          font-size: 10px;
        }
      }
      .thin {
        font-size: 8px;
        font-weight: 400;
        line-height: 1.2em;
        gap: 0;
      }
    }
    .footer {
      display: flex;
      gap: 1rem;
      align-items: flex-end;
    }
  }
}
