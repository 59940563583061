@import '../../variables.scss';

.update_ticket {
  display: grid;
  gap: 1rem;
  align-items: center;

  padding: 1rem 1rem 0;
  &_content {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 12rem 12rem 10rem;
  }
}

.custom-select {
  position: relative;
  border: 1px solid $gray;
  border-radius: 6px;
  padding: 4px;
  width: 12rem;
  transition: border 0.3s ease;
  &:hover,
  &:focus {
    border: 1px solid $primary;
  }
  select {
    width: 100%;
    font-size: 1.1rem;
    padding: 10px 30px 10px 10px;
    border: none;
    font-family: 'Inter', sans-serif;
    background-color: $darkGray;
    color: $textColor;
    outline: none;
  }
  span {
    position: absolute;
    width: 40px;
    height: 90%;
    background-color: $backgroundColor;
    right: 2px;
    top: 2px;
    pointer-events: none;
  }

  span::before {
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 7px;
    border-color: transparent transparent $primary transparent;
    top: 10%;
    left: 35%;
  }

  span::after {
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 7px;
    border-color: $primary transparent transparent transparent;
    top: 58%;
    left: 35%;
  }

  &.inside {
    span {
      position: absolute;
      width: 40px;
      height: 90%;
      background-color: $darkGray;
      right: 2px;
      top: 2px;
      pointer-events: none;
    }
  }
}
